





























import {Component, Vue} from 'vue-property-decorator';
import {TagService} from '@/api';
import {createTag, fillTag, Tag} from '@/types';
import UTabs from '@/ui-lib/tabs/Tabs.vue';
import UTab from '@/ui-lib/tabs/Tab.vue';

@Component<TagItemView>({
  components: {
    UTab,
    UTabs,
  },

  metaInfo() {
    return {
      title: this.title,
    };
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const res = await TagService.list({
        limit: 1,
        ids: [parseInt(to.params.id, 10)],
      });

      if (res.list.length === 0) {
        next(new Error("Not Found"));
        return;
      }

      next((vm) => {
        vm.tag = res.list[0];
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      const res = await TagService.list({
        limit: 1,
        ids: [parseInt(to.params.id, 10)],
      });

      if (res.list.length === 0) {
        next(new Error("Not Found"));
        return;
      }

      this.tag = res.list[0];
      next();
    } catch (e) {
      next(e);
    }
  },
})
export default class TagItemView extends Vue {
  private tag = createTag();

  private get title() {
    return `Тег: ${this.tag.name}`;
  }

  private saved(tg: Tag) {
    fillTag(tg, this.tag)
  }

  private deleted() {
    this.$router.replace({
      name: 'tagList',
    });
  }
}
